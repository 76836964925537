import {
  LENGTH,
  RAIL,
  JOIN,
  CHOP,
  CORNERSAMPLE,
  PHOTOFRAME,
  GALLERYFRAME,
  BOX,
  PT,
} from "~/utils/products";
import { decimalString } from "~/utils/fraction";
import { frameDictionary } from "~/utils/builtFrames";
import { ExpressStoreLine } from "../types";
import { unwrap } from "solid-js/store";

type Line = {
  Type: PT;
  Quantity: number;
  Plant: string;
  Cutting?: string;
  CuttingPositions?: Array<number | undefined>;
  Allowance?: string;
  BackOrder?: boolean | undefined;
  CancelBackOrder?: boolean | undefined;
  FreightAccepted?: boolean | undefined;
  Tag?: string | undefined;
  SubItems: [
    {
      SKU: string;
      Moulding: string;
      Length?: number | undefined;
      Width?: number | undefined;
      Layer?: "I" | "O" | undefined;
      LengthFraction?: string | undefined;
      WidthFraction?: string | undefined;
      Wedge?: string;
      Orientation?: "standard" | "gallery";
    }
  ];
  FrameSize?: {
    Width?: number | string;
    Length?: number | string;
  };
};

export const formattedLine = (type: PT, data: ExpressStoreLine) => {
  const line: Line = {
    Type: type,
    Quantity: data.Quantity,
    Plant: data.Plant === "defaultPlant" ? "" : data.Plant,
    BackOrder: data.BackOrder,
    CancelBackOrder: data.CancelBackOrder,
    FreightAccepted: data.FreightAccepted,
    Tag: data.TagName,
    SubItems: [
      {
        SKU: data.productData.latest!.SKU,
        Moulding: data.productData.latest!.SKU,
        Length: undefined,
        Width: undefined,
        Layer: undefined,
        LengthFraction: undefined,
        WidthFraction: undefined,
      },
    ],
  };

  switch (type) {
    case LENGTH:
      line.Quantity = 1;
      line.SubItems[0].Length = data.Length;
      line.Cutting = data.Cutting;
      if (data.CustomCut) {
        // non-unwrapped was breaking the structuredClone()
        const cuts = unwrap(data.CuttingPositions)?.filter((val) => !!val);
        line.CuttingPositions = cuts;
      }
      return line;
    case CHOP:
      line.Allowance = data.Layer === "I" ? data.Allowance : undefined;
      line.SubItems[0].Layer = data.Layer;
      line.SubItems[0].Length = Math.trunc(data.Height!);
      if (data.Height! % 1 !== 0) {
        line.SubItems[0].LengthFraction = decimalString(data.Height!);
      }
      line.SubItems[0].Width = Math.trunc(data.Width!);
      if (data.Width! % 1 !== 0) {
        line.SubItems[0].WidthFraction = decimalString(data.Width!);
      }
      line.SubItems[0].Wedge = data.Wedge === "none" ? undefined : data.Wedge;
      if (data.isStretcher) {
        line.SubItems[0].Orientation = data.Orientation;
      }
      return line;
    case JOIN:
      line.Allowance = data.Layer === "I" ? data.Allowance : undefined;
      line.SubItems[0].Layer = data.Layer;
      line.SubItems[0].Length = Math.trunc(data.Height!);
      if (data.Height! % 1 !== 0) {
        line.SubItems[0].LengthFraction = decimalString(data.Height!);
      }
      line.SubItems[0].Width = Math.trunc(data.Width!);
      if (data.Width! % 1 !== 0) {
        line.SubItems[0].WidthFraction = decimalString(data.Width!);
      }
      if (data.isStretcher) {
        line.SubItems[0].Orientation = data.Orientation;
      }
      return line;
    case RAIL:
      line.Allowance = data.Allowance;
      line.SubItems[0].Layer = data.Layer;
      line.SubItems[0].Length = Math.trunc(data.Length!);
      if (data.Length! % 1 !== 0) {
        line.SubItems[0].LengthFraction = decimalString(data.Length!);
      }
      if (data.isStretcher) {
        line.SubItems[0].Orientation = data.Orientation;
      }
      return line;
    case PHOTOFRAME:
      line.SubItems[0].SKU += `-${data.Size}`;
      line.FrameSize = {
        Width: frameDictionary[data?.Size!]?.width,
        Length: frameDictionary[data?.Size!]?.height,
      };
      return line;
    case GALLERYFRAME:
      line.SubItems[0].SKU += `GF-${data.Size}M1`;
      line.FrameSize = {
        Width: frameDictionary[data?.Size!]?.width,
        Length: frameDictionary[data?.Size!]?.height,
      };
      return line;
    case CORNERSAMPLE:
      line.Quantity = 1;
      line.SubItems[0].SKU += "-COR";
      return line;
    case BOX:
      line.SubItems[0].Length = 1;
      return line;
  }
};

export const formattedTrackingLine = (type: PT, data: any) => {
  const baseSKU = data.productData.latest.SKU;
  const formattedSKU = `${baseSKU}${
    [PT.GALLERYFRAME, PT.PHOTOFRAME].includes(type)
      ? type === PT.GALLERYFRAME
        ? `GF-${data.Size}`
        : `-${data.Size}`
      : ""
  }`;

  const name = data.productData.latest.Name;
  const category = data.productData.latest.Category;
  const collection = data.productData.latest.Collection;

  return {
    item_id: formattedSKU,
    item_name: name,
    item_category: type,
    item_category2: category,
    item_category3: collection,
    price: data.pricing.latest.Amount,
    quantity: type === LENGTH ? 1 : data.Quantity,
  };
};

// export const hasRequiredProperties = (
//   obj: ExpressLineStoreType,
//   properties: string[]
// ) => {
//   for (let i = 0; i < properties.length; i++) {
//     if (!obj.hasOwnProperty(properties[i])) {
//       return false;
//     }
//   }
//   return true;
// };

export const categoryMapping = (category?: string): string | undefined => {
  if (!category) return undefined;
  switch (category) {
    case "Simply Roma":
      return "SR";
    case "Roma One":
      return "RO";
    case "Roma Elite":
      return "RE";
    default:
      return undefined;
  }
};
